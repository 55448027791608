<template>
<!--  :getPopupContainer="triggerNode =>  triggerNode.parentNode|| document.body"加上这个会导致第一次弹出位置不对-->
  <a-popover :title="title" trigger="hover" placement="topRight" overlayClassName="axcbdj990 boxLine" >
    <template slot="content">
      <p>1.可用库存需要大于等于允许退海信的数量才可以进行鉴定结果确认！</p> 
      <p>2.如果库存不足，您可以执行以下操作调节库存：</p> 
      <!-- 暂时隐藏 后续开放 -->
      <!-- <span style="color:#1890FF"><a @click="gotolr" style="color:#1890FF">•执行分销商退货或者进行零售退货来调节质量等级为T的库存 >></a></span> -->
      <span style="color:#1890FF"><a @click="gotoKc" style="color:#1890FF">•在库存调整模块进行库存调整 >></a></span>
    </template>
      <i  class="iconfont icon-tijiwenti-pc toast"></i>
  </a-popover>
</template>
<script>
export default {
  // 示例
  // <volume-pop></volume-pop>
  name: "checkComputation2",
  data() {
    return {
      title:'核算可用库存',
      list:null
    };
  },
  mounted(){
    this.getMessage()
  },
  methods:{
    // 跳转库存调整页面
    gotoKc() {
      let routeUrl = this.$router.resolve({
          path: '/stockCenter/adjustmentApply',
            
      });
      window.open(routeUrl.href, "_blank");
    },
    gotolr(){
      let routeUrl = this.$router.resolve({
          path: '/hisenseReturn/hisenseRrturnInput',
            
      });
      window.open(routeUrl.href, "_blank");
    },
    getMessage(){
       const msg =  this.$configTotal({
          route: '/cart',
          id:'14181084184',
        })
        this.list = msg.msgDesc
    }
  }
};
</script>

<style lang="less">
  // 物流规则
  .boxLine{
    .ant-popover-inner{
      padding:0 16px;
      font-size: 14px;
      color: #777777;
      width: 380px!important;
      height: 180px!important;
    }
  }
  .axcbdj990{
    .ant-popover-inner{
      padding:0 16px;
      font-size: 14px;
      color: #777777;
    }
    .ant-popover-title{
      padding:12px 0px 12px 0px;
      box-sizing: border-box;
      min-width:150px;
      font-size: 16px;
    }
    .ant-popover-inner-content{
     
      padding:12px 0px 12px 0px;
      font-size: 14px;
      line-height: 28px;
    }
  }
  .toast {
      color:#00AAA6;
      cursor: pointer;
      font-size: 16px;
      position: relative;
      top:1px;
      left: 3px;
    }
  
</style>
