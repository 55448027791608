import service from "@/utils/request";

//获取订单详情
export function getOrderDetail(params) {
  return service({
    url: "/defectiveProduct/detail.nd",
    params
  });
}

// 确定鉴定结果/验机结果
export function getReturnResult(params) {
  return service({
    url: "/defectiveProduct/confirmReturnResults.nd",
    params
  });
}
