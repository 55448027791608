import bg from '@/assets/purchaseOrder/bg.png'
import Process from './components/Process.vue'
import Processkt from './components/Processkt.vue'
import ProcessBX from './../ReturnApplicationDetails/components/ProcessBX'
import checkComputation2 from '../components/checkComputation2'
import moment from "moment";
import {
    getOrderDetail,
    // 确定验机/鉴定结果
    getReturnResult
} from './api'

moment.locale("zh-cn");
export default {
    name: "channelWarehouse",
    components: {
        Process,
        Processkt,
        checkComputation2,
        ProcessBX
    },
    data() {
        return {
            clickJD:true,
            clickYnji:true,
            breadcrumbData: [{
                    path: "/index",
                    name: "index",
                    title: "首页"
                },
                {
                    path: "",
                    name: "",
                    title: "退货申请详情"
                },
                
            ],
            bg: bg,
            //表格搜索
            tableSearch: '',
            appraisalcolumns:[
                {
                    title: '行号',
                    dataIndex: 'lineNum',
                    customRender:(text,record,index)=>`${index+1}`,
                    width: 80,
                    ellipsis: true
                },
                {
                    title: '鉴定工单号',
                    dataIndex: 'crmCode',
                    width:150,
                    ellipsis: true
                },
                {
                    title: '鉴定日期',
                    dataIndex: 'identifyDateStr',
                    width:150,
                    ellipsis: true
                },
                {
                    title: '型号',
                    dataIndex: 'zzprdmodel',
                    width:200,
                    ellipsis: true
                },
                {
                    title: '鉴定机号',
                    dataIndex: 'serialNumber',
                    width:250,
                    ellipsis: true
                },
                {
                    title: '鉴定说明',
                    dataIndex: 'returnReason',
                    width:150,
                    ellipsis: true
                },
                {
                    title: '故障分类',
                    dataIndex: 'problemType',
                    width:150,
                    ellipsis: true
                },
                {
                    title: '批次',
                    dataIndex: 'batch',
                    width:150,
                    ellipsis: true
                },
                {
                    title: '返机类型',
                    dataIndex: 'returnType',
                    width:150,
                    ellipsis: true
                },
                {
                    title: '处理方式',
                    dataIndex: 'dealMethod',
                    width:150,
                    ellipsis: true
                },
                {
                    title: '分公司验机故障',
                    dataIndex: 'problemReason',
                    width:150,
                    ellipsis: true
                },
                {
                    title: '不规范项',
                    dataIndex: 'noStandard',
                    width:150,
                    ellipsis: true
                },
                
            ],
            
            pager: {
                count: '',
                pageNo: 1,
                pageSize: 10
              },
          
            // 表格数据
            tableData: [],
            identifyList:[],
            // 仅查看已选
            onlyShowChecked: false,
            sendObject: [],
          
            // 接口数据备份
            NeworderDetail: {
                NeworderHeader: {}
            },
            selectedRows: [],
            chukuTotal: 0,
            chukuVolume: 0,
            daichuku: 0,
            // 全选list
            selectedRowKeys: [],
            // 搜索用
            orgCode: '',
            searchLength: 0,
            model: '',
            pageLoadFlag: false,
            isLoading: false,
            buchais: false,
            orderDetail:{},
            salesDefectiveList:[],
            
        };
    },
    computed:{
        // 鉴定数量
        identificationNumber:function (){
            let total = 0
            if(this.orderDetail && Array.isArray(this.salesDefectiveList)){
                this.salesDefectiveList.forEach(item=>{
                total+=item.identifyNum 
                })
            }
            return parseInt(total||0)
        },
        // 允许数量
        agreeNumber:function (){
            let total = 0
            if(this.orderDetail && Array.isArray(this.salesDefectiveList)){
                this.salesDefectiveList.forEach(item=>{
                total+=item.agreeReturnNum 
                })
            }
            return parseInt(total||0)
        },
        // 拒绝数量
       disagreeReturnNum:function (){
            let total = 0
            if(this.orderDetail && Array.isArray(this.salesDefectiveList)){
                this.salesDefectiveList.forEach(item=>{
                total+=item.disagreeReturnNum 
                })
            }
            return parseInt(total||0)
        },
        totalLoadVolume:function (){
          let total = 0
          if(this.orderDetail&&Array.isArray(this.salesDefectiveList)){
            this.salesDefectiveList.forEach(item=>{
              if(item.loadVolume) {
                total+=item.agreeReturnNum*Math.max(item.loadVolume,0)
              }
            })
          }
          return total
        }
    },
    mounted() {
        if(this.$route.query.id){
            let id  = this.$route.query.id
            this.getDetail(id)
        } else {
            this.$message.warning('获取详情失败')
        }
    },
    methods: {
        goBack() {
            window.close()
        },
        // 获取详情
        getDetail(id){
            let data = {
              id:id
            }
            getOrderDetail(data).then(res=>{
                this.orderDetail = res.data
                this.salesDefectiveList = res.data.salesDefective.detailList
                this.tableData = res.data.identifyList.identifyCheckDetailList
                this.identifyList = res.data.identifyList
               
                if(res.data.identifyList.status == 'identify'){
                    this.appraisalcolumns.splice(10,2)
                }
            }).catch(err => {
               this.$message.warning('获取详情失败')
            })
        },
        // 确定验机结果
        getReturns(type) {
            this.pageLoadFlag = true
            let id  = this.$route.query.id
            let data = {
                resultId:id,
                type:type
            }
            getReturnResult(data).then(res=>{
                
                if(res.data.result == 'N') {
                    this.$message.warning(res.data.msg)
                } else {
                    if(type == 'Y') {
                        this.$message.success(res.data.msg)
                        this.clickYnji = false
                    }
                    if(type == 'J') {
                        this.$message.success(res.data.msg)
                        this.clickJD = false
                       
                    }
                }
                this.getDetail(id)
                this.pageLoadFlag = false
               
            }).catch(err => {
                this.$message('失败')
            })            
        }
    },
};